import { FedexDefaultValues } from './FedexDefaultValues';
import { LabelSpecification } from './LabelSpecification';

export const Fedex = {
  id: '',
  clientId: '',
  configurationName: '',
  tenantId: '',
  clientEntityState: 4,
  password: '',
  courier: '',
  courierCode: 'fedex',
  integration: 'FEDEX',
  key: '',
  accountNumber: '',
  meterNumber: '',
  urlAddress: '',
  dropoffType: '',
  serviceType: '',
  packagingType: '',
  additionalServices: { services: [] },
  labelSpecification: LabelSpecification,
  fedexDefaultValues: FedexDefaultValues,
};
