import { CodDetail } from './CodDetail';

export const FedexDefaultValues = {
  id: '',
  clientId: '',
  tenantId: '',
  configurationName: '',
  clientEntityState: 4,
  codDetail: CodDetail,
};
