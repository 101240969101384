<template>
  <div>
    <form-section :title="$t('courierSet.payment')">
      <v-row>
        <v-col>
          <select-field
            v-model="getCodDetail.collectionType"
            :title="$t('courierSet.collectionType')"
            :filed-items="collectionTypes"
          />
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { collectionTypes } from '@/constants/Speditors/Fedex/CollectionTypes';

export default {
  props: {
    isNew: { type: Boolean, default: null },
  },
  data: () => ({
    collectionTypes,
  }),
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getCodDetail() {
      return this.getConfigurationSet().fedexDefaultValues.codDetail;
    },
  },
};
</script>

<style scoped></style>
