<template>
  <configuration-set
    v-if="!!getConfigurationSet()?.courier"
    :components="components"
    :speditor="speditor"
    :current-component="currentComponent"
    :loading="loading"
    @@changeComponent="changeComponent"
    @@save="save"
  >
    <template #content>
      <ServiceAuthorization
        v-show="currentComponent === 'ServiceAuthorization'"
        :is-new="isNew"
      />

      <AdditionalServices
        v-show="currentComponent === 'AdditionalServices'"
        :is-new="isNew"
      />

      <LabelSettings v-show="currentComponent === 'LabelSettings'" />

      <AdditionalPayments v-show="currentComponent === 'AdditionalPayments'" />
    </template>
  </configuration-set>
</template>

<script>
import { Fedex } from '@/constants/Speditors/Fedex/configuration/Fedex';
import ServiceAuthorization from '@/views/Speditors/Fedex/Forms/ServiceAuthorization.vue';
import AdditionalPayments from '@/views/Speditors/Fedex/Forms/AdditionalPayments.vue';
import LabelSettings from '@/views/Speditors/Fedex/Forms/LabelSettings.vue';
import AdditionalServices from '@/views/Speditors/Fedex/Forms/AdditionalServices.vue';
import ConfigurationSet from '@/components/shared/ConfigurationSet.vue';
import configurationMixin from '@/mixins/configurationMixin';

export default {
  components: {
    ServiceAuthorization,
    AdditionalPayments,
    LabelSettings,
    AdditionalServices,
    ConfigurationSet,
  },
  mixins: [configurationMixin],
  data: () => ({
    currentComponent: 'ServiceAuthorization',
    speditor: 'Fedex',
    components: [
      { code: 'ServiceAuthorization', name: 'courierSet.serviceAuthorizationName' },
      { code: 'AdditionalServices', name: 'courierSet.additionalServices' },
      { code: 'LabelSettings', name: 'courierSet.labelSettings' },
      { code: 'AdditionalPayments', name: 'courierSet.additionalPaymentsName' },
    ],
  }),
  methods: {
    setNewConfiguration() {
      this.setConfigSet({ response: Fedex });
    },
  },
};
</script>
