export const serviceTypes = [
  { id: 'FEDEX_INTERNATIONAL_PRIORITY_EXPRESS', name: 'FedEx International Priority® Express' },
  { id: 'INTERNATIONAL_PRIORITY_FREIGHT', name: 'FedEx International Priority® Freight' },
  { id: 'FEDEX_INTERNATIONAL_PRIORITY', name: 'FedEx International Priority®' },
  { id: 'FEDEX_INTERNATIONAL_CONNECT_PLUS', name: 'FedEx International Connect Plus®' },
  { id: 'INTERNATIONAL_ECONOMY', name: 'FedEx International Economy®' },
  { id: 'INTERNATIONAL_ECONOMY_FREIGHT', name: 'FedEx International Economy® Freight' },
  { id: 'INTERNATIONAL_FIRST', name: 'Usługa FedEx International First' },
  {
    id: 'INTERNATIONAL_PRIORITY_DISTRIBUTION',
    name: 'FedEx International Priority Direct Distribution®',
  },
  { id: 'INTERNATIONAL_DISTRIBUTION_FREIGHT', name: 'International Distribution Freight' },
  {
    id: 'INTERNATIONAL_ECONOMY_DISTRIBUTION',
    name: 'FedEx International Economy DirectDistribution',
  },
  { id: 'FEDEX_REGIONAL_ECONOMY', name: 'FedEx® Regional Economy' },
  { id: 'FEDEX_REGIONAL_ECONOMY_FREIGHT', name: 'FedEx® Regional Economy Freight' },
  { id: 'FEDEX_NEXT_DAY_FREIGHT', name: 'FedEx® Next Day Freight (tylko Wielka Brytania)' },
  { id: 'FEDEX_NEXT_DAY_EARLY_MORNING', name: 'FedEx Next Day by 9 AM (tylko Wielka Brytania)' },
  { id: 'FEDEX_NEXT_DAY_MID_MORNING', name: 'FedEx Next Day by 10 AM (tylko Wielka Brytania)' },
  { id: 'FEDEX_NEXT_DAY_AFTERNOON', name: 'FedEx Next Day by 12 Noon (tylko Wielka Brytania)' },
  { id: 'FEDEX_NEXT_DAY_END_OF_DAY', name: 'FedEx Next Day End of Day (tylko Wielka Brytania)' },
  {
    id: 'FEDEX_DISTANCE_DEFERRED',
    name: 'FedEx Distance Deferred (tylko Wielka Brytania, usługa Economy)',
  },
  { id: 'PRIORITY_OVERNIGHT', name: 'FedEx Priority Overnight® (wybrane rynki)' },
  { id: 'FEDEX_FIRST', name: 'FedEx First' },
  { id: 'FEDEX_PRIORITY_EXPRESS', name: 'FedEx Priority Express' },
  { id: 'FEDEX_PRIORITY', name: 'FedEx Priority' },
  { id: 'FEDEX_PRIORITY_EXPRESS_FREIGHT', name: 'FedEx Priority Express Freight' },
  { id: 'FEDEX_PRIORITY_FREIGHT', name: 'FedEx Priority Freight' },
];
