export const labelStockTypes = [
  { id: 0, name: 'PAPER_4X6' },
  { id: 1, name: 'PAPER_4X675' },
  { id: 2, name: 'PAPER_4X8' },
  { id: 3, name: 'PAPER_4X9' },
  { id: 4, name: 'PAPER_7X475' },
  { id: 5, name: 'PAPER_85X11_BOTTOM_HALF_LABEL' },
  { id: 6, name: 'PAPER_85X11_TOP_HALF_LABEL' },
  { id: 7, name: 'PAPER_LETTER' },
  { id: 8, name: 'STOCK_4X6' },
  { id: 9, name: 'STOCK_4X675' },
  { id: 10, name: 'STOCK_4X675_LEADING_DOC_TAB' },
  { id: 11, name: 'STOCK_4X675_TRAILING_DOC_TAB' },
  { id: 12, name: 'STOCK_4X8' },
  { id: 13, name: 'STOCK_4X9' },
  { id: 14, name: 'STOCK_4X9_LEADING_DOC_TAB' },
  { id: 15, name: 'STOCK_4X9_TRAILING_DOC_TAB' },
];
