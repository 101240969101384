export const LabelSpecification = {
  id: '',
  clientId: '',
  clientEntityState: 4,
  tenantId: '',
  configurationName: '',
  imageType: '',
  labelFormatType: '',
  labelStockType: '',
  labelPrintingOrientation: '',
};
