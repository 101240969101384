<template>
  <div>
    <form-section :title="$t('courierSet.setData')">
      <v-row>
        <v-col>
          <text-field
            v-model="getConfigurationValues.configurationName"
            :title="$t('courierSet.configurationName')"
            rules="required|not_custom_shipment"
          />
        </v-col>
        <v-col>
          <text-field
            v-model="getConfigurationValues.id"
            :title="$t('courierSet.configurationId')"
            rules="required"
            :disabled="true"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <select-field
            v-model="getConfigurationValues.courierCode"
            :title="$t('courierSet.deliverySpeditor')"
            rules="required"
            :filed-items="SHIPMENT_METHOD_CODES"
            disabled
          />
        </v-col>
      </v-row>
    </form-section>

    <form-section :title="$t('courierSet.authData')">
      <v-row>
        <v-col>
          <text-field
            v-model="getConfigurationValues.key"
            :title="$t('courierSet.key')"
            rules="required"
          />
        </v-col>
        <v-col>
          <text-field
            v-model="getConfigurationValues.password"
            :title="$t('courierSet.password')"
            type="password"
            rules="required"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <text-field
            v-model="getConfigurationValues.meterNumber"
            :title="$t('courierSet.meterNumber')"
            rules="required"
          />
        </v-col>
        <v-col>
          <text-field
            v-model="getConfigurationValues.accountNumber"
            :title="$t('courierSet.accountNumber')"
            :hint="$t('courierSet.accountNumberHint')"
            rules="required"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <text-field
            v-model="getConfigurationValues.urlAddress"
            :title="$t('courierSet.urlAddress')"
            :rules="'required|url'"
          />
        </v-col>
      </v-row>
    </form-section>

    <form-section :title="$t('courierSet.serviceType')">
      <v-row>
        <v-col>
          <select-field
            v-model="getConfigurationValues.serviceType"
            :title="$t('courierSet.serviceType')"
            rules="required"
            :filed-items="serviceTypes"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <select-field
            v-model="getConfigurationValues.dropoffType"
            :title="$t('courierSet.deliveryMethod')"
            rules="required"
            :filed-items="dropoffTypes"
          />
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { mapGetters } from 'vuex';
import { dropoffTypes } from '@/constants/Speditors/Fedex/DropoffTypes';
import { serviceTypes } from '@/constants/Speditors/Fedex/ServiceTypes';
import { SHIPMENT_METHOD_CODES } from '@/constants/ShipmentMethodCodes.js';

export default {
  props: {
    isNew: { type: Boolean, default: false },
  },
  data() {
    return {
      SHIPMENT_METHOD_CODES,
      dropoffTypes,
      serviceTypes,
    };
  },
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getConfigurationValues() {
      return this.getConfigurationSet();
    },
  },
  mounted() {
    this.getConfigurationValues.courierCode = 'fedex';
    if (this.isNew) {
      this.getConfigurationValues.id = uuidv4();
      this.getConfigurationValues.urlAddress = 'https://apis-sandbox.fedex.com';
    }
  },
};
</script>

<style scoped>
.col {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
